var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md12":"","sm12":"","lg12":""}},[_c('v-col',{attrs:{"cols":"12","offset-md":"6","offset-lg":"6","sm":"12","md":"6","lg":"6"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Suche"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"custom-filter":_vm.customfilter,"height":"inherit","items-per-page":-1,"items":_vm.arbeitsnachweise,"search":_vm.filter},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Arbeitsnachweise ("+_vm._s(_vm.arbeitsnachweise.length)+")")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","color":"success"},on:{"click":function($event){return _vm.$router.push('/arbeitsnachweis/new/-1')}}},on),[_c('v-icon',[_vm._v("mdi-playlist-plus")])],1)]}}])},[_c('span',[_vm._v("Neuen Arbeitsnachweis anlegen")])])],1)]},proxy:true},{key:"item.assignmentnumber",fn:function(ref){
var item = ref.item;
return [(item.assignmentid && item.assignmentnumber)?_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.$router.push('assignment/' + item.assignmentid)}}},[_vm._v(_vm._s(item.assignmentnumber))]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == '0')?_c('span',{staticClass:"orange-highlight"},[_vm._v("Erstellt")]):_vm._e(),(item.status == '1')?_c('span',{staticClass:"orange-highlight"},[_vm._v("In Bearbeitung")]):_vm._e(),(item.status == '2')?_c('span',{staticClass:"darkred-highlight"},[_vm._v("Übermittelt")]):_vm._e(),(item.status == '3')?_c('span',{staticClass:"green-highlight"},[_vm._v("Abgeschlossen")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.handleClick(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Arbeitsnachweis bearbeiten")])]),(_vm.role !== 'worker')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":item.status == '3' || item.assignmentid != null,"color":"success","small":"","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.setAssignment(item)}}},on),[_c('v-icon',[_vm._v("mdi-clipboard-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Auftrag zuweisen")])]):_vm._e(),(_vm.role !== 'worker' || item.created_by === _vm.userid)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","small":"","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDeleteItem(item.id, item.number, 'an')}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Auftrag löschen")])]):_vm._e()]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.creator ? item.creator : "-"))]),_c('small',[_vm._v(_vm._s(_vm.$formatDateTime(item.created_at)))])]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [(item.info && item.info.customername)?_c('div',{staticClass:"bold"},[_vm._v(" "+_vm._s(item.info.customername)+" ")]):_vm._e(),(item.info && item.info.address)?_c('small',[_vm._v(_vm._s(item.info.address))]):_vm._e()]}}])})],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.confirm),callback:function ($$v) {_vm.confirm=$$v},expression:"confirm"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Löschen")]),(_vm.deleteMode == 'an')?_c('v-card-text',[_vm._v(" Sind sie sicher, dass Sie den Arbeitsnachweis "),_c('b',[_vm._v(_vm._s(_vm.deleteName))]),_vm._v(" unwiederruflich löschen wollen? ")]):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v("Ja")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.confirm = false}}},[_vm._v("Nein")])],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Auftrag zuweisen")]),_c('v-card-text',[_c('p',[_vm._v("Bitte wählen Sie einen Auftrag aus")]),_c('v-select',{attrs:{"item-text":"assignmentnumber","loading":_vm.isLoading,"items":_vm.assignments,"return-object":"","label":"Auftrag auswählen"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.assignmentnumber)}}),(item.customername)?_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.customername)}}):_vm._e()],1)]}}]),model:{value:(_vm.selectedAssigment),callback:function ($$v) {_vm.selectedAssigment=$$v},expression:"selectedAssigment"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","text":"","disabled":!_vm.selectedAssigment},on:{"click":function($event){return _vm.assign()}}},[_vm._v("Zuweisen")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Abbrechen")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }