<template>
  <v-container fill-height fluid>
    <v-layout wrap>
      <v-flex md12 sm12 lg12>
        <v-col cols="12" offset-md="6" offset-lg="6" sm="12" md="6" lg="6">
          <v-text-field
            v-model="filter"
            append-icon="search"
            label="Suche"
          ></v-text-field>
        </v-col>
        <v-data-table
          :headers="headers"
          :custom-filter="customfilter"
          height="inherit"
          class="row-pointer"
          @click:row="handleClick"
          :items-per-page="-1"
          :items="arbeitsnachweise"
          :search="filter"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title
                >Arbeitsnachweise ({{
                  arbeitsnachweise.length
                }})</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    @click="$router.push('/arbeitsnachweis/new/-1')"
                    fab
                    color="success"
                  >
                    <v-icon>mdi-playlist-plus</v-icon>
                  </v-btn>
                </template>
                <span>Neuen Arbeitsnachweis anlegen</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <template v-slot:item.assignmentnumber="{ item }">
            <v-btn
              outlined
              color="primary"
              @click="$router.push('assignment/' + item.assignmentid)"
              v-if="item.assignmentid && item.assignmentnumber"
              >{{ item.assignmentnumber }}</v-btn
            >
          </template>
          <template v-slot:item.status="{ item }">
            <span v-if="item.status == '0'" class="orange-highlight"
              >Erstellt</span
            >
            <span v-if="item.status == '1'" class="orange-highlight"
              >In Bearbeitung</span
            >
            <span v-if="item.status == '2'" class="darkred-highlight"
              >Übermittelt</span
            >
            <span v-if="item.status == '3'" class="green-highlight"
              >Abgeschlossen</span
            >
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="handleClick(item)"
                  fab
                  small
                  color="primary"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Arbeitsnachweis bearbeiten</span>
            </v-tooltip>
            <!--   <v-tooltip left v-if="role !== 'worker'">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="printNachweis(item.id, item.number)"
                  fab
                  :disabled="!item.assignmentid"
                  small
                  color="primary"
                >
                  <i class="material-icons">print</i>
                </v-btn>
              </template>
              <span>Arbeitsnachweis drucken</span>
            </v-tooltip> -->
            <v-tooltip left v-if="role !== 'worker'">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :disabled="item.status == '3' || item.assignmentid != null"
                  @click.stop="setAssignment(item)"
                  color="success"
                  small
                  fab
                >
                  <v-icon>mdi-clipboard-check</v-icon>
                </v-btn>
              </template>
              <span>Auftrag zuweisen</span>
            </v-tooltip>
            <v-tooltip
              left
              v-if="role !== 'worker' || item.created_by === userid"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click.stop="confirmDeleteItem(item.id, item.number, 'an')"
                  color="error"
                  small
                  fab
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Auftrag löschen</span>
            </v-tooltip>
          </template>
          <template v-slot:item.created="{ item }">
            <div>{{ item.creator ? item.creator : "-" }}</div>
            <small>{{ $formatDateTime(item.created_at) }}</small>
          </template>
          <template v-slot:item.info="{ item }">
            <div class="bold" v-if="item.info && item.info.customername">
              {{ item.info.customername }}
            </div>
            <small v-if="item.info && item.info.address">{{
              item.info.address
            }}</small>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="confirm" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Löschen</v-card-title>
          <v-card-text v-if="deleteMode == 'an'">
            Sind sie sicher, dass Sie den Arbeitsnachweis
            <b>{{ deleteName }}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="deleteItem()">Ja</v-btn>
            <v-btn color="error" text @click="confirm = false">Nein</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">Auftrag zuweisen</v-card-title>
          <v-card-text>
            <p>Bitte wählen Sie einen Auftrag aus</p>
            <!--  <v-autocomplete
              v-model="model"
              :items="items"
              :loading="isLoading"
              clearable
              :search-input.sync="search"
              item-text="assignmentnumber"
              label="Aufträge durchsuchen"
              placeholder="Geben sie eine Auftragsnummer oder kundennamen ein"
              return-object
            > -->
            <v-select
              item-text="assignmentnumber"
              :loading="isLoading"
              :items="assignments"
              return-object
              label="Auftrag auswählen"
              v-model="selectedAssigment"
            >
              <!--   <template v-slot:item="data">
                <strong>{{ data.item.assignmentnumber }}</strong> ({{ data.item.cname }}) 
              </template> -->
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.assignmentnumber"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-if="item.customername"
                    v-text="item.customername"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
            <!--   </v-autocomplete> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              text
              :disabled="!selectedAssigment"
              @click="assign()"
              >Zuweisen</v-btn
            >
            <v-btn color="error" text @click="dialog = false">Abbrechen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      confirm: false,
      dialog: false,
      deleteName: "",
      deleteId: "",
      deleteMode: "",
      currentAn: null,
      selectedAssigment: null,
      assignments: [],
      arbeitsnachweise: [],
      items: [],
      isLoading: false,
      model: null,
      filter: "",
      search: null,
      showPricesToRole: false,
      headers: [
        {
          text: "Arbeitsnachweisnummer",
          align: "left",
          value: "number",
        },
        {
          text: "Kunde",
          align: "left",
          value: "info",
        },
        {
          text: "Betreff",
          sortable: false,
          value: "subject",
        },
        {
          text: "Typ",
          sortable: false,
          value: "type",
        },
        {
          text: "Kommentar",
          sortable: false,
          value: "comment",
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
        },
        {
          text: "Erstellt von / am",
          sortable: false,
          value: "created",
        },
        {
          text: "Aktion",
          align: "center",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  watch: {
    search(val) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("term", val);
      this.$http({
        method: "post",
        url: "getAssignmentsSearch.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            this.items = response.data.data;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted: function () {
    this.getArbeitsnachweise();
  },
  computed: {
    role() {
      return this.$store.getters.user.role;
    },
    online() {
      return this.$store.getters.online;
    },

    userid() {
      return this.$store.getters.user.id;
    },
  },
  methods: {
    customfilter(val, search, item) {
      search = search.toString().toLowerCase();
      return (
        (item.number && item.number.toLowerCase().indexOf(search) > -1) ||
        (item.assignmentnumber &&
          item.assignmentnumber.toLowerCase().indexOf(search) > -1) ||
        (item.type && item.type.toLowerCase().indexOf(search) > -1)
      );
    },
    getOpenAssignments() {
      this.isLoading = true;
      this.$http({
        method: "post",
        url: "getOpenAssignments.php",
      })
        .then((response) => {
          this.isLoading = false;
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            this.assignments = response.data.data;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    },
    getArbeitsnachweise() {
      this.$http({
        method: "post",
        url: "getArbeitsnachweise.php",
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            const an = response.data.data;
            an.forEach((a) => {
              if (a.info && a.info != "NULL" && this.$isJson(a.info)) {
                a.info = JSON.parse(a.info);
              } else {
                a.info = null;
              }
            });
            this.arbeitsnachweise = an;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleClick(item) {
      if (this.role === "worker" || item.created_by === this.userid) {
        this.$router.push(
          "/arbeitsnachweis/execute/" + item.assignmentid + "/" + item.id
        );
      } else {
        this.$router.push(
          "/arbeitsnachweis/view/" + item.assignmentid + "/" + item.id
        );
      }
    },
    confirmDeleteItem(id, name, mode) {
      this.deleteName = name;
      this.deleteId = id;
      this.deleteMode = mode;
      this.confirm = true;
    },
    deleteItem() {
      let formData = new FormData();
      formData.append("id", this.deleteId);
      let url;
      if (this.deleteMode === "an") {
        url = "deleteArbeitsnachweis.php";
      }
      if (!url) {
        return;
      }
      this.$http({
        method: "post",
        url: url,
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.data && response.data.success) {
            let msg;
            if (this.deleteMode === "an") {
              this.arbeitsnachweise.forEach((a, i) => {
                if (a.id === this.deleteId) {
                  this.arbeitsnachweise.splice(i, 1);
                  this.deleteName = "";
                  this.deleteId = "";
                  this.confirm = false;
                }
              });
              msg = "Der Arbeitsnachweis wurde erfolgreich gelöscht.";
            }
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          error;
        });
    },
    assign() {
      const selected = this.selectedAssigment;
      const assignmentid = selected && selected.id;
      const assignmentnumber = selected && selected.assignmentnumber;
      const arbeitsnachweisid = this.currentAn.id;
      if (!assignmentid || !arbeitsnachweisid || !assignmentnumber) {
        return;
      }
      let formData = new FormData();
      formData.append("assignmentid", assignmentid);
      formData.append("arbeitsnachweisid", arbeitsnachweisid);
      this.$http({
        method: "post",
        url: "setArbeitsnachweisToAssignment.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success) {
            this.arbeitsnachweise.forEach((a, i) => {
              if (a.id === arbeitsnachweisid) {
                this.arbeitsnachweise.splice(i, 1);
              }
            });
            this.selectedAssigment = null;
            this.currentAn = null;
            this.dialog = false;
            const msg =
              "Der Arbeitsnachweis wurde erfolgreich einem Auftrag zugewiesen";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Der Arbeitsnachweis konnte nicht einem Auftrag zugewiesen werden. Bitte versuchen Sie es erneut";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setAssignment(item) {
      this.currentAn = item;
      this.dialog = true;
      if (this.assignments || !this.assignments.length) {
        this.getOpenAssignments();
      }
    },
    printNachweis(id, number) {
      let formData = new FormData();
      formData.append("id", id);
      var self = this;
      this.$http({
        method: "post",
        url: "printNachweis.php",
        data: formData,
        responseType: "blob",
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          /*  if (response.data && response.data.success && response.data.file) {
            const url =
              this.$http.defaults.serverURL +
              "/print/prints/" +
              response.data.file;
            window.open(url, "_blank");
          } else {
            const msg =
              "Der Druck konnte nicht erstellt werden. Bitte versuchen Sie es erneut";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          } */
          if (response.status === 200 && response.data) {
            const blobUrl = URL.createObjectURL(
              new Blob([response.data], { type: "application/pdf" })
            );
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = blobUrl;
            link.download = "Arbeitsnachweis_" + number + ".pdf";
            link.type = "application/pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(blobUrl);
            const msg = "Der Druck wurde erfolgreich erstellt.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Der Druck konnte nicht erstellt werden. Bitte versuchen Sie es erneut";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          const msg =
            "Der Druck konnte nicht erstellt werden. Bitte versuchen Sie es erneut";
          const color = "error";
          self.$store.dispatch("snackbar", { msg, color });
        });
    },
    changeCustomer() {
      const id = this.info && this.info.customerid;
      if (id) {
        this.getStandorte(id);
      }
    },
    getStandorte(id) {
      let formData = new FormData();
      formData.append("id", id);
      this.$http({
        method: "post",
        url: "getStandorte.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.notAllowed
          ) {
            this.$router.push("/");
          }
          if (response.data && response.data.success && response.data.data) {
            this.standorte = response.data.data;
            this.info.standortid = null;
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
        });
    },
  },
};
</script>
